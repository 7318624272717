<!-- TODO: fix console error -->
<script setup>
import VueApexCharts from "vue3-apexcharts";
import abbreviate from "number-abbreviate";
import { debounce } from "lodash-es";

const props = defineProps({
  uniqueClicks: {
    type: Number,
    required: true,
    default: 0,
  },
  totalClicks: {
    type: Number,
    required: true,
    default: 0,
  },
});

const calculateRate = (part, total) => {
  if (total > 0) {
    return parseFloat(((part / total) * 100).toFixed(2));
  }
  return 0;
};

const uniqueClicksRate = computed(
  () => calculateRate(props.uniqueClicks, props.totalClicks) || 0
);
const nonUniqueClicksRate = computed(
  () => calculateRate(props.totalClicks - props.uniqueClicks, props.totalClicks) || 0
);

const series = computed(() => [uniqueClicksRate.value, nonUniqueClicksRate.value]);

const chartOptions = computed(() => ({
  chart: {
    id: "clicks-rate-summary-chart",
    type: "radialBar",
  },
  title: {
    text: "Clicks Rate Summary",
    margin: 20,
    offsetX: 10,
    offsetY: 2,
    style: {
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },
  noData: {
    text: "No clicks data recorded",
    align: "center",
    verticalAlign: "middle",
    offsetX: 0,
    offsetY: 0,
    style: {
      color: undefined,
      fontSize: "1rem",
      fontWeight: 700,
      fontFamily: "Avenir, Helvetica, Arial, sans-serif",
    },
  },

  plotOptions: {
    radialBar: {
      dataLabels: {
        total: {
          show: true,
          label: "TOTAL",
          formatter: () => abbreviate(props.totalClicks, 2),
        },
      },
      hollow: {
        margin: 5,
        // size: "70%",
      },
    },
  },
  legend: {
    show: true,
    floating: true,
    fontSize: "16px",
    position: "bottom",

    labels: {
      useSeriesColors: true,
    },
  },

  labels: ["Unique", "Non Unique"],
}));

const chartRef = ref(null);

const debouncedUpdateData = debounce(() => {
  if (chartRef.value) {
    chartRef.value?.updateSeries(series.value);
  }
}, 300);

watch(() => [props.uniqueClicks, props.totalClicks], debouncedUpdateData, {
  immediate: true,
});

onUnmounted(() => {
  debouncedUpdateData.cancel();
});
</script>

<template>
  <div class="h-full p-5">
    <VueApexCharts
      ref="chartRef"
      height="300"
      width="100%"
      type="radialBar"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<style></style>
